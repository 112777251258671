.frosted-backdrop {
  z-index: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../assests/frosted.png");
  background-size: cover;
  opacity: 0.2;
}

.frosted-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.frosted-header {
  padding: 1em;
  z-index: 1;
}

.frosted-content {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  z-index: 1;
}

