@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --ff-primary: "Poppins";
  --main-color: rgb(0, 34, 78);
  --main-text: rgb(208, 219, 233);
  --header-text-color: var(--main-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255,255,255,.3);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,.3);
  border-radius: 6px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.App {
  font-family: "Poppins";
  font-family: var(--ff-primary);
  color: rgb(208, 219, 233);
  color: var(--main-text);
  background-image: url(/static/media/background-outside.365bdbd1.jpg);
  background-size: cover;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-image 1s ease-in-out;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: smaller;
  }
}

a:link, a:visited {
  color: rgb(208, 219, 233);
  color: var(--main-text);
}

.App.inside {
  background-image: url(/static/media/background-inside.c903a5bf.jpg);
  transition: background-image 1s ease-in-out;
  --header-text-color: var(--main-text);
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.menu {
  position: absolute;
  left: 0px;
  top: 0px;
}

.container {
  border-radius: 2em;
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: solid 4px transparent;
  background-clip: padding-box;
  box-shadow: 10px 10px 10px 10px rgba(1, 44, 107, 0.2);
  width: 90%;
  height: 80%;
  max-width: 900px;
  max-height: 600px;
  overflow: hidden;
}

.container-full-height {
  height: unset;
  max-height: unset;
}

.vipps-logo {
  height: 10vmin;
  pointer-events: none;
  margin: 0.5rem;
}

.izettle-logo {
  height: 10vmin;
  pointer-events: none;
  margin: 0.5rem;
}

.logos {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: calc(20px + 2vmin);
  line-height: 0.5em;
}

.vipps-number {
  font-weight: 900;
  margin-bottom: 0.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: black;
}
.App-header .dark {
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #264f5a;
}

.App-link .dark {
  color: #61dafb;
}
.App-days {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 1rem;
}
.day {
  font-size: 4vmin;
}
.day-amount {
  font-weight: bold;
  font-size: 6vmin;
}

.card {
  margin-left: 3vmin;
  margin-right: 3vmin;
  margin-top: 1vh;
  margin-bottom: 1vh;
  background-color: white;
  align-items: center;
  box-shadow: 
    12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  justify-content: center;
  padding: 1rem;
  min-width: 15vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  background: rgb(208, 219, 233);
  background: var(--main-text);
  color: rgb(0, 34, 78);
  color: var(--main-color);
  padding: 1em 1.3em 1em 1.3em;
  border: solid 1px;
  outline: unset;
  border-radius: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
}

button:hover {
  color: rgb(208, 219, 233);
  color: var(--main-text);
  background: rgb(0, 34, 78);
  background: var(--main-color);
}
.frosted-backdrop {
  z-index: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/frosted.53818e29.png);
  background-size: cover;
  opacity: 0.2;
}

.frosted-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.frosted-header {
  padding: 1em;
  z-index: 1;
}

.frosted-content {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  z-index: 1;
}


.videos {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.video {
  padding: 1rem;
  width: 560px;
  max-width: 100%;
}

iframe {
  max-width: 100%;
}
.gsmk-logo {
  pointer-events: none;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}

.home-content {
  /*flex: 1 1 70%;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  margin-top: 1em;
}

h1, h2, h3 {
  margin-top: 0em;
  margin-bottom: 0em;
  font-weight: bolder;
}

h3 {
  line-height: 0.9em;
  font-weight: lighter;
}

h2 {
  margin-bottom: 1em;
}

.home-main {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.home-content > p {
  max-width: 600px;
}

.home-logo {
  position: absolute;
  z-index: -1;
  right: -15%;
  min-width: 30%;
  height: 100%;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.submit-cell {
  display: flex;
  padding-top: 2em;
  flex-direction: row-reverse;
}

.application-registered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 70%;
  text-align: center;
}
.contact-main {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.gsmk-logo {
  pointer-events: none;
  opacity: 0.1;
  width: 100%;
  height: 100%;
}

.home-content-diploma {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-top: 1em;
}

.home-content-diploma > h1, .home-content-diploma > h2, .home-content-diploma > h3 {
  margin-top: 0em;
  margin-bottom: 1em;
  text-align: center;
  font-weight: bolder;
}

.home-content-diploma > h3 {
  margin-bottom: 0.2em;
  font-weight: lighter;
}

.home-content > p {
  max-width: 600px;
}

.home-content-diploma + .home-logo {
  position: absolute;
  z-index: -1;
  right: -100px;
  min-width: 30%;
  height: 100%;
  top: 0px;
}

.signatures {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
}

.signature {
  padding: 1em;
}

.signature > img {
  max-width: 150px;
}
.header {
  display: flex;
  font-size: large;
  position: absolute;
  top: 0px;
  min-width: 600px;
  font-size: large;
}

.header > * {
  flex-basis: 100%;
  padding-top: 1em;
}

.header > a {
  text-decoration: none;
  text-align: center;
  color: var(--header-text-color);
  transition: color 1s ease-in-out;
}

.header > a.active {
  border-bottom: solid 2px;
}

@media only screen and (max-width: 600px) {
  .header {
    flex-direction: column;
    z-index: 999;
    min-width: unset;
    background: rgba(0,0,0,0.9);
    left: 0px;
    bottom: 0px;
    padding: 2em;
  }

  .header > a {
    color: unset;
  }

  .header > a.active {
    border-bottom: none;
    font-weight: bolder;
  }
}

.application > :not(h3) {
  margin-left: 3ch;
  font-size: 0.8rem;
}

.application + .application {
  margin-top: 2rem;
}

.applications-title {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-block: 1.5rem;
}
