.application > :not(h3) {
  margin-left: 3ch;
  font-size: 0.8rem;
}

.application + .application {
  margin-top: 2rem;
}

.applications-title {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-block: 1.5rem;
}