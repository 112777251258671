.videos {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.video {
  padding: 1rem;
  width: 560px;
  max-width: 100%;
}

iframe {
  max-width: 100%;
}