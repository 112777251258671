.gsmk-logo {
  pointer-events: none;
  opacity: 0.1;
  width: 100%;
  height: 100%;
}

.home-content-diploma {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-top: 1em;
}

.home-content-diploma > h1, .home-content-diploma > h2, .home-content-diploma > h3 {
  margin-top: 0em;
  margin-bottom: 1em;
  text-align: center;
  font-weight: bolder;
}

.home-content-diploma > h3 {
  margin-bottom: 0.2em;
  font-weight: lighter;
}

.home-content > p {
  max-width: 600px;
}

.home-content-diploma + .home-logo {
  position: absolute;
  z-index: -1;
  right: -100px;
  min-width: 30%;
  height: 100%;
  top: 0px;
}

.signatures {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
}

.signature {
  padding: 1em;
}

.signature > img {
  max-width: 150px;
}