.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.submit-cell {
  display: flex;
  padding-top: 2em;
  flex-direction: row-reverse;
}

.application-registered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 70%;
  text-align: center;
}