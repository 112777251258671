.header {
  display: flex;
  font-size: large;
  position: absolute;
  top: 0px;
  min-width: 600px;
  font-size: large;
}

.header > * {
  flex-basis: 100%;
  padding-top: 1em;
}

.header > a {
  text-decoration: none;
  text-align: center;
  color: var(--header-text-color);
  transition: color 1s ease-in-out;
}

.header > a.active {
  border-bottom: solid 2px;
}

@media only screen and (max-width: 600px) {
  .header {
    flex-direction: column;
    z-index: 999;
    min-width: unset;
    background: rgba(0,0,0,0.9);
    left: 0px;
    bottom: 0px;
    padding: 2em;
  }

  .header > a {
    color: unset;
  }

  .header > a.active {
    border-bottom: none;
    font-weight: bolder;
  }
}