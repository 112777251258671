@import url('https://fonts.googleapis.com/css?family=Poppins');

:root {
  --ff-primary: "Poppins";
  --main-color: rgb(0, 34, 78);
  --main-text: rgb(208, 219, 233);
  --header-text-color: var(--main-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255,255,255,.3);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,.3);
  border-radius: 6px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.App {
  font-family: var(--ff-primary);
  color: var(--main-text);
  background-image: url("./assests/background-outside.jpg");
  background-size: cover;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: smaller;
  }
}

a:link, a:visited {
  color: var(--main-text);
}

.App.inside {
  background-image: url("./assests/background-inside.jpg");
  -webkit-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
  --header-text-color: var(--main-text);
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.menu {
  position: absolute;
  left: 0px;
  top: 0px;
}

.container {
  border-radius: 2em;
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: solid 4px transparent;
  background-clip: padding-box;
  box-shadow: 10px 10px 10px 10px rgba(1, 44, 107, 0.2);
  width: 90%;
  height: 80%;
  max-width: 900px;
  max-height: 600px;
  overflow: hidden;
}

.container-full-height {
  height: unset;
  max-height: unset;
}

.vipps-logo {
  height: 10vmin;
  pointer-events: none;
  margin: 0.5rem;
}

.izettle-logo {
  height: 10vmin;
  pointer-events: none;
  margin: 0.5rem;
}

.logos {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: calc(20px + 2vmin);
  line-height: 0.5em;
}

.vipps-number {
  font-weight: 900;
  margin-bottom: 0.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: black;
}
.App-header .dark {
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #264f5a;
}

.App-link .dark {
  color: #61dafb;
}
.App-days {
  display: flex;
  gap: 2rem;
  margin: 1rem;
}
.day {
  font-size: 4vmin;
}
.day-amount {
  font-weight: bold;
  font-size: 6vmin;
}

.card {
  margin-left: 3vmin;
  margin-right: 3vmin;
  margin-top: 1vh;
  margin-bottom: 1vh;
  background-color: white;
  align-items: center;
  box-shadow: 
    12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  justify-content: center;
  padding: 1rem;
  min-width: 15vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  background: var(--main-text);
  color: var(--main-color);
  padding: 1em 1.3em 1em 1.3em;
  border: solid 1px;
  outline: unset;
  border-radius: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
}

button:hover {
  color: var(--main-text);
  background: var(--main-color);
}