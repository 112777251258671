.gsmk-logo {
  pointer-events: none;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}

.home-content {
  /*flex: 1 1 70%;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  margin-top: 1em;
}

h1, h2, h3 {
  margin-top: 0em;
  margin-bottom: 0em;
  font-weight: bolder;
}

h3 {
  line-height: 0.9em;
  font-weight: lighter;
}

h2 {
  margin-bottom: 1em;
}

.home-main {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.home-content > p {
  max-width: 600px;
}

.home-logo {
  position: absolute;
  z-index: -1;
  right: -15%;
  min-width: 30%;
  height: 100%;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}